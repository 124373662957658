import { createTheme } from '@mui/material'
import '@fontsource/noto-serif-jp'

const fontFamily = ['Noto Serif JP', 'serif', 'sans-serif'].join(',')

const theme = createTheme({
    typography: {
        fontFamily: fontFamily,
    },
    palette: {
        primary: {
            main: '#8D6449',
            contrastText: '#fff',
        },
        secondary: {
            main: '#757575',
            contrastText: '#fff',
        },
        background: {
            default: '#ffffff',
        },
    },
})

export default theme
