exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-designerorder-index-tsx": () => import("./../../../src/pages/designerorder/index.tsx" /* webpackChunkName: "component---src-pages-designerorder-index-tsx" */),
  "component---src-pages-fullorder-catalog-tsx": () => import("./../../../src/pages/fullorder/catalog.tsx" /* webpackChunkName: "component---src-pages-fullorder-catalog-tsx" */),
  "component---src-pages-fullorder-index-tsx": () => import("./../../../src/pages/fullorder/index.tsx" /* webpackChunkName: "component---src-pages-fullorder-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-salon-tsx": () => import("./../../../src/pages/salon.tsx" /* webpackChunkName: "component---src-pages-salon-tsx" */),
  "component---src-pages-semiorder-arrange-tsx": () => import("./../../../src/pages/semiorder/arrange.tsx" /* webpackChunkName: "component---src-pages-semiorder-arrange-tsx" */),
  "component---src-pages-semiorder-index-tsx": () => import("./../../../src/pages/semiorder/index.tsx" /* webpackChunkName: "component---src-pages-semiorder-index-tsx" */),
  "component---src-pages-trying-on-tsx": () => import("./../../../src/pages/trying-on.tsx" /* webpackChunkName: "component---src-pages-trying-on-tsx" */),
  "component---src-templates-customize-tsx": () => import("./../../../src/templates/customize.tsx" /* webpackChunkName: "component---src-templates-customize-tsx" */),
  "component---src-templates-static-1-tsx": () => import("./../../../src/templates/static1.tsx" /* webpackChunkName: "component---src-templates-static-1-tsx" */)
}

